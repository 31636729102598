import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { ModalConfirm, SecondaryNavigationBar, SecondaryNavigationItem } from '@ppui/ui-components'
import { HandleModalButtonArgType } from '@ppui/ui-components/dist/cjs/components/ModalComponent/types'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { isNil } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetUserProfile } from 'src/lib/queries/useGetUserProfile'
import { CONFIRMATION_EXIT_MESSAGE } from 'src/routes/Investor/EditOrder/constants'
import { useStores } from 'src/store/rootStore'

import { INVESTOR_USER_GUIDE_URL } from '../../constants/urls'
import { useQueryClientFeatureFlags } from '../../lib/queries/useGetFeatureFlags'
import { getSecondaryNavLinks } from './hooks'
import { isExitModalVisible } from './utils'

const SecondaryHeader = observer(({ fullWidth = false }: { fullWidth?: boolean }) => {
  const {
    orderStore: { isOrderDetailsEdited },
  } = useStores()
  const { data, isLoading } = useGetUserProfile()
  const location = useLocation()
  const navigate = useNavigate()
  const { currentFeatureFlags } = useQueryClientFeatureFlags()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [url, setUrl] = useState('')

  const handleButtonClick = useCallback(
    (type: HandleModalButtonArgType) => {
      if (type === 'ok') {
        // remove listener to popState event before page gets navigated, to avoid exit alert
        window.onpopstate = null
        navigate(url)
      }
      setIsOpen(false)
    },
    [navigate, url]
  )

  const onClickHandler = (url: string): void => {
    const isModalVisible: boolean = isExitModalVisible(location.pathname, url)

    if (INVESTOR_USER_GUIDE_URL === url) {
      window.open(INVESTOR_USER_GUIDE_URL, 'UserGuide', 'menubar=no,toolbar=no,location=no')
      return
    }
    // if changes were made and page supports exit warning, show exit alert, if no, redirect
    if (isOrderDetailsEdited && isModalVisible) {
      setIsOpen(true)
      setUrl(url)
      return
    }
    navigate(url)
  }

  if (isNil(data) || isLoading) return <></>

  const links = getSecondaryNavLinks(data.accountType, currentFeatureFlags)

  return (
    <div
      className={classNames('ioi-flex ioi-flex-grow ioi-flex-col', {
        'max-xl:ioi-z-[1]': !fullWidth,
      })}
    >
      <SecondaryNavigationBar styles="ioi-pt-0 ioi-pb-0 ioi-my-[0.857rem]">
        {links
          .filter((link) => link.isAvailable)
          .map((link, index) => {
            return (
              <SecondaryNavigationItem
                key={index}
                clickHandler={() => onClickHandler(link.url)}
                icon={link.icon}
                isActive={location.pathname === link.url}
                text={link.text}
                dataQa={link.dataQa}
                styles="ioi-mr-2 ioi-ml-0"
              />
            )
          })}
      </SecondaryNavigationBar>
      <ModalConfirm
        isOpen={isOpen}
        size="normal"
        title="Leave this page"
        labels={{
          success: 'Exit editing',
          cancel: 'Keep editing',
        }}
        icon={<ShieldExclamationIcon className="ioi-h-6 ioi-w-6 ioi-text-amber-700" />}
        handleButtonClick={handleButtonClick}
        content={<>{CONFIRMATION_EXIT_MESSAGE}</>}
      />
    </div>
  )
})

export default React.memo(SecondaryHeader) as typeof SecondaryHeader
