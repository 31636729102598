import { LoadingScreen } from '@ppui/ui-components'
import { AppContainer } from 'components/AppContainer'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { RequireApplicationEnabled } from './RequierApplicationEnabled'
import { RequireAuth } from './RequireAuth'
import { Retry } from './Retry'

/* eslint-disable @typescript-eslint/promise-function-async */
const NewOrder = React.lazy(() => Retry(() => import('./routes/Investor/NewOrder')))
const EditOrder = React.lazy(() => Retry(() => import('./routes/Investor/EditOrder')))
const NotFound = React.lazy(() => Retry(() => import('./components/NotFound')))
const InvestorOrderIndications = React.lazy(() =>
  Retry(() => import('./routes/Investor/OrderIndications'))
)
const BankerOrSellSideSalesOrderIndications = React.lazy(() =>
  Retry(() => import('./routes/MultiUser/OrderIndications'))
)
const OrderView = React.lazy(() => Retry(() => import('./routes/Investor/OrderView')))
const OrderPreferences = React.lazy(() => Retry(() => import('./routes/Investor/OrderPreferences')))
const OrderHistory = React.lazy(() => Retry(() => import('./routes/Investor/OrderHistory')))
const DeskManagement = React.lazy(() => Retry(() => import('./routes/Investor/DeskManagement')))
const AcknowledgementAcknowledged = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Acknowledged'))
)
const AcknowledgementAlreadyApplied = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/AlreadyApplied'))
)
const AcknowledgementDeclined = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Declined'))
)
const AcknowledgementError = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Error'))
)
const AcknowledgementStale = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Stale'))
)
const MainPages = React.lazy(() => Retry(() => import('./routes/Investor/MainPages')))
const RequestAccess = React.lazy(() => Retry(() => import('./routes/Investor/RequestAccess')))
const DealsPage = React.lazy(() => Retry(() => import('./routes/DealsPage')))
const ApiDocs = React.lazy(() => Retry(() => import('./routes/Banker/ApiDocs')))
const AdminSTEmails = React.lazy(() => Retry(() => import('./routes/Admin/SalesTraderEmails')))
const AdminOrganizations = React.lazy(() => Retry(() => import('./routes/Admin/Organizations')))
const AdminDesks = React.lazy(() => Retry(() => import('./routes/Admin/Desks')))
const EscapeHatch = React.lazy(() => Retry(() => import('./routes/Admin/EscapeHatch')))
const AdminDomainUsers = React.lazy(() => Retry(() => import('./routes/Admin/DomainUsers')))

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <MainPages />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="new-order"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <NewOrder />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="order-indications"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <InvestorOrderIndications />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="view-order"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <OrderView />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="edit-order-indication"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <EditOrder />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="preferences"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <OrderPreferences />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="history"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <OrderHistory />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="request-access"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequestAccess />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/acknowledged"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementAcknowledged />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/already-applied"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementAlreadyApplied />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/declined"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementDeclined />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/stale"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementStale />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/error"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementError />
            </Suspense>
          }
        />
        <Route
          path="desk-management"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <DeskManagement />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="deals"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <DealsPage />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="deals/order-indications"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <BankerOrSellSideSalesOrderIndications />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="api-docs"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <ApiDocs />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="admin/sales-trader-emails"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <AdminSTEmails />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="admin/organizations"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <AdminOrganizations />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="admin/escape-hatch"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <EscapeHatch />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="admin/desks"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <AdminDesks />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="admin/users"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer fullWidth>
                    <AdminDomainUsers />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <RequireApplicationEnabled>
                  <AppContainer>
                    <NotFound />
                  </AppContainer>
                </RequireApplicationEnabled>
              </RequireAuth>
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
